<template>
  <div>
    <v-data-table :items="carts" :headers="headers" @click:row="rowClicked">
    </v-data-table>

    <v-dialog v-model="showDetails">
      <v-card>
        <v-card-title>Details</v-card-title>
        <v-card-text>{{ currentShoppingCart }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="showDetails = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ShoppingCarts',
  components: {},

  data: () => ({
    carts: [],
    headers: [
      { text: 'Date', align: 'start', value: 'cartCreated' },
      { text: 'Cart ID', align: 'start', value: 'cartId' }
    ],
    showDetails: false,
    currentShoppingCart: undefined
  }),

  beforeMount () {
    this.loadCarts()
  },

  methods: {
    loadCarts () {
      fetch('/api/sales/carts', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.carts = data.shoppingCarts
        })
    },

    rowClicked (item) {
      this.currentShoppingCart = item
      this.showDetails = true
    }
  }
}
</script>
