<template>
  <div>
    <v-data-table :items="salesRecords" :headers="headers" :sort-by="salesDate" :sort-desc="false"
      @click:row="rowClicked">
    </v-data-table>

    <v-dialog v-model="showDetailsDialog">
      <v-card>
        <v-card-title>Details for sale</v-card-title>
        <v-card-text>
          {{ currentItem }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="showDetailsDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SalesRecords',
  components: {},

  data: () => ({
    salesRecords: [],
    currentItem: {},
    headers: [
      { text: 'Date', align: 'start', value: 'salesDate' },
      { text: 'Customer', align: 'start', value: 'customerName' },
      { text: 'Amount', align: 'start', value: 'amountInCents' }
    ],
    showDetailsDialog: false
  }),

  beforeMount: function () {
    this.loadRecords()
  },

  methods: {
    loadRecords () {
      fetch('/api/sales/records', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.salesRecords = data.salesRecords
        })
    },

    rowClicked (item) {
      fetch(`/api/sales/records/${item.recordId}`, {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.currentItem = data
          this.showDetailsDialog = true
        })
    }
  }
}
</script>
