<template>
  <div>
    <v-data-table data-cy="inventoryTable" :items="inventory" :headers="headers" :items-per-page="-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon data-cy="deleteItem" small @click.stop.prevent="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-icon data-cy="editItem" small @click.stop.prevent="editItem(item)">
          mdi-pencil
        </v-icon>
      </template>
      <template v-slot:top>
        <v-toolbar>
          <v-dialog v-model="addDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn data-cy="addItem" color="primary" dark class="mb-2" v-bind="attrs" v-on="on">Add Item</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Add Item</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="productId" v-model="editedItem.productId" label="Product UUID">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="productName" v-model="editedItem.productName" label="Product name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="stockQuantity" v-model="editedItem.stockQuantity" label="Stock quantity">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="minimumPriceInCents" v-model="editedItem.minimumPriceInCents"
                        label="Minimum Price">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="recommendedPriceInCents" v-model="editedItem.recommendedPriceInCents"
                        label="Recommended Price">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="maximumPriceInCents" v-model="editedItem.maximumPriceInCents"
                        label="Maximum Price">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="addItemClose" @click="closeAddDialog"> Cancel </v-btn>
                <v-btn data-cy="addItemSave" color="primary" @click="saveAddedItem(editedItem)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="closeDeleteItem" color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn data-cy="confirmDeleteItem" color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">Edit Item</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    Current stock quantity is <span data-cy="stockQuantity">{{ editedItem.productQuantity }}</span>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field data-cy="newStockQuantity" v-model="editedItem.newStockQuantity"
                        label="Change in quantity">
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn data-cy="editItemClose" @click="closeEditDialog"> Cancel </v-btn>
                <v-btn data-cy="editItemSave" color="primary" @click="saveEditedItem(editedItem)">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>
    </v-data-table>

    <h2>Inventory</h2>
    <ul>
      <li>Define products (without images)</li>
      <li>Work on inventory quantities</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InventoryList',
  components: {},

  data: () => ({
    addDialog: false,
    dialogDelete: false,
    dialogEdit: false,
    inventory: [],
    editedItem: {},
    headers: [
      { text: 'Product ID', align: 'start', value: 'productId' },
      { text: 'Product Name', align: 'start', value: 'productName' },
      { text: 'Stock Quantity', align: 'start', value: 'productQuantity' },
      { text: 'Price (min)', align: 'start', value: 'minimumPriceInCents' },
      { text: 'Price', align: 'start', value: 'recommendedPriceInCents' },
      { text: 'Price (max)', align: 'start', value: 'maximumPriceInCents' },
      { text: 'Enabled', align: 'start', value: 'enabled' },
      { text: 'Actions', value: 'actions', sortable: false }
    ]
  }),

  mounted: function () {
    this.loadInventory()
  },

  methods: {
    loadInventory () {
      fetch('/api/sales/inventory', {
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.inventory = data.inventory
        })
    },

    closeAddDialog () {
      this.addDialog = false
    },

    saveAddedItem (item) {
      fetch('/api/sales/inventory', {
        method: 'POST',
        headers: { 'Content-type': 'application/json', Authorization: 'Bearer ' + `${this.$keycloak.token}` },
        body: JSON.stringify(item)
      })
        .then((response) => {
          if (response.status === 201) {
            this.addDialog = false
            this.editedItem = {}
            this.loadInventory()
          } else { alert('Response was ' + response.status) }
        })
        .catch((error) => console.error('Save item: /api/sales/inventory failed', error))
    },

    deleteItem (item) {
      this.editedIndex = this.inventory.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    closeDelete () {
      this.dialogDelete = false
    },

    deleteItemConfirm () {
      fetch(`/api/sales/inventory/${this.editedItem.id}`, {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            this.dialogDelete = false
            this.loadInventory()
          } else console.error('Cannot delete. Status: ', response.status)
        })
        .catch((error) => console.error(error))
    },

    editItem (item) {
      this.editedIndex = this.inventory.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogEdit = true
    },

    closeEditDialog () {
      this.dialogEdit = false
    },

    saveEditedItem (item) {
      fetch(`/api/sales/inventory/${item.productId}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer ' + `${this.$keycloak.token}`
        },
        body: JSON.stringify({
          quantityChange: item.newStockQuantity
        })
      })
        .then((response) => {
          if (response.status === 200) {
            this.dialogEdit = false
            this.loadInventory()
          } else console.error('Cannot update. Status: ', response.status)
        })
        .catch((error) => console.error(error))
    }
  }

}
</script>
