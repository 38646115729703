<template>
  <div>
    <v-tabs v-model="activeTab">
      <v-tab to="/sales/inventory" ripple>Inventory</v-tab>
      <v-tab to="/sales/records" ripple>Records</v-tab>
      <v-tab to="/sales/carts" ripple>Carts</v-tab>
    </v-tabs>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'SalesHome',
  components: {
  },

  data () {
    return {
      activeTab: `/sales/${this.id}`
    }
  }
}
</script>
